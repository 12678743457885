import { AuthApiService } from "@/common/api.service";
import {
	LOAD_CATEGORY_LIST_URL,
	ADD_CATEGORY_ENTRY_URL,
	EDIT_CATEGORY_ENTRY_URL,
	DELETE_CATEGORY_ENTRY_URL,
} from "@/config/api.endpoints";

const ConfigService = {
	load_category_list(filters={}) {
		return AuthApiService.post(LOAD_CATEGORY_LIST_URL, filters);
	},
	add_category_entry(entry) {
		return AuthApiService.post(ADD_CATEGORY_ENTRY_URL, entry);
	},
	edit_category_entry(entry) {
		return AuthApiService.post(EDIT_CATEGORY_ENTRY_URL, entry);
	},
	delete_category_entry(entry) {
		return AuthApiService.post(DELETE_CATEGORY_ENTRY_URL, entry);
	},
	
};

export default ConfigService;