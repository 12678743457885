import { AuthApiService } from "@/common/api.service";
import {
	GET_INVENTORY_LIST_URL,
	GET_INVENTORY_HISTORY_URL,
	ADD_INVENTORY_ITEM_URL,
	EDIT_INVENTORY_ITEM_URL,
	DELETE_INVENTORY_ITEM_URL
} from "@/config/api.endpoints";

const InventoryService = {
	get_inventory_list(filters) {
		return AuthApiService.post(GET_INVENTORY_LIST_URL, filters);
	},
	add_inventory_item(user) {
		return AuthApiService.post(ADD_INVENTORY_ITEM_URL, user);
	},
	edit_inventory_item(user) {
		return AuthApiService.post(EDIT_INVENTORY_ITEM_URL, user);
	},
	delete_inventory_item(user){
		return AuthApiService.post(DELETE_INVENTORY_ITEM_URL, user);
	},
	get_inventory_history(filters) {
		return AuthApiService.post(GET_INVENTORY_HISTORY_URL, filters);
	},
};

export default InventoryService;