import UserService from "@/common/user.service";
import TokenService from "@/common/token.service";

//import router from "@/router";

import {
    VALIDATE_SESSION, 
    LOGIN, 
    LOGOUT, 
	LOAD_USER,
	
	SWITCH_COMPANY,
	UPDATE_SETTINGS
} from "@/store/actions.type";
import {
    STORE_TOKEN,
    CLEAR_TOKEN, 
    STORE_USER_DATA,
	RESET_STATE
} from "@/store/mutations.type";

const initialState = {
	user: {},
	isAuthenticated: !!TokenService.getToken()
};

export const state = { ...initialState };

const getters = {
	user(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	}
};

export const actions = {
	[LOGIN](context, info) {
		// Make our API CAll
		return UserService.login({
			"email": info.email,
			"password": info.password
		})
		.then(data => {
			context.commit(STORE_TOKEN, data.data.token);
			UserService.wipe_user_data();
			return data;
		});
	},
	[SWITCH_COMPANY](context, info) {
		// Make our API CAll
		return UserService.switch_company(info)
		.then(data => {
			context.commit(STORE_TOKEN, data.data.token);
			UserService.wipe_user_data();
			return data;
		});
	},
	[UPDATE_SETTINGS](context, info) {
		// Make our API CAll
		return UserService.update_settings(info)
		.then(data => {
			context.commit(STORE_TOKEN, data.data.token);
			UserService.wipe_user_data();
			context.dispatch(LOAD_USER, true);
			return data;
		});
	},
	[LOGOUT](context) {
		return new Promise((resolve) => {
			context.commit(CLEAR_TOKEN);
			resolve();
		});
	},
	[VALIDATE_SESSION](context) {
		return new Promise((resolve, reject) => {
			if (context.state.isAuthenticated && TokenService.getToken()) {
				resolve();
			} else {
				context.commit(CLEAR_TOKEN);
				reject();
			}
		});
	},
	[LOAD_USER](context, force) {
		if(force === undefined) force = false;
		//If force, delete cached data
		if(force) {
			UserService.wipe_user_data();
		}

		//Look up user data locally
		var user = UserService.get_user_data();
		if(user !== null) {
			context.commit(STORE_USER_DATA, user);
			return;
		}
		// We don't have the user locally, make our API Call
		return UserService.getUser()
			.then(response => {
				UserService.store_user_data(response.data);
				context.commit(STORE_USER_DATA, response.data);
				return response.data;
			});
	}
};

export const mutations = {
	[STORE_TOKEN](state, access_token) {
		state.isAuthenticated = true;
		TokenService.saveToken(access_token);
	},
	[STORE_USER_DATA](state, user) {
		state.user = user;
	},
	[CLEAR_TOKEN](state, expired) {
		// Error message
		var message = '';

		// Check if expiry, or hard logout
		if(expired === undefined) message = '';
		else message = "Your session has timed out.";

		// Wipe state & token
		state.isAuthenticated = false;
		TokenService.destroyToken();
		UserService.wipe_user_data();


		console.log(message);
		// Forward to Login page w/ message
		//router.replace({ name: 'login', query: { date: Date.now() }, params: { error: message }});
	},
	[RESET_STATE]() {
		for (const f in state) {
			state[f] = initialState;
		}
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};