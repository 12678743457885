// User related Actions
export const VALIDATE_SESSION = "validateSession";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOAD_USER = "loadUser";
export const UPDATE_SETTINGS = "updateSettings";

// Company Actions
export const SWITCH_COMPANY = "switchCompany";

// Password reset actions
export const REQUEST_RESET = "requestPasswordReset";
export const RESET_VALIDATE = "validateResetCode";
export const RECOVER_ACCOUNT = "recoverAccount";

// System actions
export const ON_LOAD = "onLoad";
export const OFF_LOAD = "offLoad";

// User Management Actions
export const ADD_USER = "addUser";
export const LOAD_USER_LIST = "loadUserList";
export const EDIT_USER = "editUser";
export const SUSPEND_USER = "suspendUser";
export const ACTIVATE_USER = "activateUser";

// Admin
export const LOAD_COMPANY_LIST = "loadCompanyList";
export const ADD_COMPANY = "addCompany";
export const EDIT_COMPANY = "editCompany";

/////////
// Application specific actions
/////////
export const ADD_INVENTORY_ITEM = "addInventoryItem";
export const LOAD_INVENTORY_LIST = "loadInventoryList";
export const EDIT_INVENTORY_ITEM = "editInventoryItem";
export const DELETE_INVENTORY_ITEM = "deleteInventoryItem";

export const ADD_CATEGORY_ENTRY = "addCategoryEntry";
export const LOAD_CATEGORY_LIST = "loadCategoryList";
export const EDIT_CATEGORY_ENTRY = "editCategoryEntry";
export const DELETE_CATEGORY_ENTRY = "deleteCategoryEntry";

export const LOAD_DESCRIPTION_LIST = "loadDescriptionList";
export const LOAD_SIZE_LIST = "loadSizeList";
export const LOAD_CONNECTION_LIST = "loadConnectionList";

export const LOAD_TICKET_LIST = "loadTicketList";
export const LOAD_TICKET_DETAILS = "loadTicketDetails";
export const ADD_TICKET_ENTRY = "addTicketEntry";
export const EDIT_TICKET_ENTRY = "editTicketEntry";
export const DELETE_TICKET_ENTRY = "deleteTicketEntry";

export const COMPLETE_TICKET = "completeTicket";
export const CANCEL_TICKET = "cancelTicket";
export const PENDING_TICKET = "pendingTicket";

export const LOAD_NONINVENTORY_LIST = "loadNonInventoryList";