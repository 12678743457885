import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

createApp(App)
	.use(store)
	.use(router)
	.use(require('v-click-outside'))
	.use(require('v-tooltip'))
	.mount('#app')
