// User Mutations
export const CLEAR_TOKEN = "clearToken";
export const STORE_TOKEN = "storeToken";
export const STORE_USER_DATA = "storeUserData";
export const RESET_STATE = "resetModuleState";

// System mutations
export const SET_LOADING = "setLoading";

// User Management
export const SET_USER_LIST = "setUserList";

/////////
// Application specific actions
/////////
export const SET_INVENTORY_LIST = "setInventoryList";
export const SET_NONINVENTORY_LIST = "setNonInventoryList";
export const SET_CATEGORY_LIST = "setCategoryList";
export const SET_DESCRIPTION_LIST = "setDescriptionList";
export const SET_SIZE_LIST = "setSizeList";
export const SET_CONNECTION_LIST = "setConnectionList";
export const SET_TICKET_LIST = "setTicketList";
export const SET_TICKET_DETAILS = "setTicketDetails";


export const SET_COMPANY_LIST = "setCompanyList";