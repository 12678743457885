import { createStore } from 'vuex'

import user from "./user.module"; 
import system from "./system.module"; 
import users from "./users.module"; 
import inventory from "./inventory.module"; 
import noninventory from "./noninventory.module";
import config from "./config.module"; 
import ticket from "./ticket.module"; 
import company from "./company.module"; 

export default createStore({
	modules: {
		user,
		system,
		users,
		inventory,
		noninventory,
		config,
		ticket,
		company
	}
});