import TicketService from "@/common/ticket.service";

import {
	LOAD_TICKET_LIST,
	ADD_TICKET_ENTRY,
	EDIT_TICKET_ENTRY,
	DELETE_TICKET_ENTRY,
	LOAD_TICKET_DETAILS,
	COMPLETE_TICKET,
	CANCEL_TICKET,
	PENDING_TICKET
} from "@/store/actions.type";
import {
	SET_TICKET_LIST,
	SET_TICKET_DETAILS
} from "@/store/mutations.type";

const state = {
	tickets: [
	],
	details: {}
};

const getters = {
	ticket_list(state) {
		return state.tickets;
	},
	ticket_details(state){
		return state.details;
	}
};

const actions = {
	[LOAD_TICKET_LIST](context, entry) {
		// Make our API CAll
		return TicketService.load_ticket_list(entry)
		.then(data => {
			context.commit(SET_TICKET_LIST, data.data);
			return data;
		});
	},
	[LOAD_TICKET_DETAILS](context, ticket_id) {
		// Make our API CAll
		return TicketService.get_ticket_details(ticket_id)
		.then(data => {
			context.commit(SET_TICKET_DETAILS, data.data);
			return data;
		});
	},
	[ADD_TICKET_ENTRY](context, entry) {
		// Make our API CAll
		return TicketService.add_ticket_entry(entry)
		.then(data => {
			return data;
		});
	},
	[EDIT_TICKET_ENTRY](context, entry) {
		// Make our API CAll
		return TicketService.edit_ticket_entry(entry)
		.then(data => {
			return data;
		});
	},
	[DELETE_TICKET_ENTRY](context, entry) {
		// Make our API CAll
		return TicketService.delete_ticket_entry(entry)
		.then(data => {
			return data;
		});
	},
	[COMPLETE_TICKET](context, entry) {
		// Make our API CAll
		console.log(entry);
		return TicketService.complete_ticket(entry)
		.then(data => {
			return data;
		});
	},
	[CANCEL_TICKET](context, entry) {
		// Make our API CAll
		return TicketService.cancel_ticket(entry)
		.then(data => {
			return data;
		});
	},
	[PENDING_TICKET](context, entry) {
		// Make our API CAll
		return TicketService.pending_ticket(entry)
		.then(data => {
			return data;
		});
	}
};

const mutations = {
	[SET_TICKET_LIST](state, list) {
		state.tickets = list;
	},
	[SET_TICKET_DETAILS](state, details){
		state.details = details;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};