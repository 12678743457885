// List of API endpoint URLs
export default {};

import { LOCAL_URL, API_URL } from "@/config/api.config";

// User endpoints
export const LOGIN_URL = API_URL+"user/login";
export const LOAD_USER_URL = API_URL+"user/details";
export const UPDATE_SETTINGS_URL = API_URL+"user/save_settings"
export const SWITCH_COMPANY_URL = API_URL+"user/switch_company";

// Reset endpoints
export const REQUEST_RESET_URL = LOCAL_URL+"user/request_reset.json";
export const VALIDATE_REQUEST_URL = LOCAL_URL+"user/validate_reset.json";
export const RESET_PASSWORD_URL = LOCAL_URL+"user/reset_password.json";

// User Management
export const GET_USER_LIST_URL = API_URL+"users/list";
export const ADD_USER_URL = API_URL+"users/add";
export const EDIT_USER_URL = API_URL+"users/edit";
export const SUSPEND_USER_URL = API_URL+"users/suspend";
export const ACTIVATE_USER_URL = API_URL+"users/activate";

// admin
export const GET_COMPANY_LIST_URL = API_URL+"company/list";
export const ADD_COMPANY_URL = API_URL+"company/add";
export const EDIT_COMPANY_URL = API_URL+"company/edit";

/////////
// Application specific actions
/////////
export const GET_INVENTORY_LIST_URL = API_URL+"inventory/list";
export const ADD_INVENTORY_ITEM_URL = API_URL+"inventory/add";
export const EDIT_INVENTORY_ITEM_URL = API_URL+"inventory/edit";
export const DELETE_INVENTORY_ITEM_URL = API_URL+"inventory/delete";
export const GET_INVENTORY_HISTORY_URL = API_URL+"inventory/history";


export const LOAD_CATEGORY_LIST_URL = API_URL+"config/list";
export const ADD_CATEGORY_ENTRY_URL = API_URL+"config/add";
export const EDIT_CATEGORY_ENTRY_URL = API_URL+"config/edit";
export const DELETE_CATEGORY_ENTRY_URL = API_URL+"config/delete";


export const LOAD_TICKET_LIST_URL = API_URL+"ticket/list";
export const LOAD_TICKET_DETAILS_URL = API_URL+"ticket/details";
export const ADD_TICKET_ENTRY_URL = API_URL+"ticket/add";
export const EDIT_TICKET_ENTRY_URL = API_URL+"ticket/edit";
export const DELETE_TICKET_ENTRY_URL = API_URL+"ticket/delete";

export const COMPLETE_TICKET_URL = API_URL+"ticket/complete"
export const CANCEL_TICKET_URL = API_URL+"ticket/cancel"
export const PENDING_TICKET_URL = API_URL+"ticket/pending"

export const GET_NONINVENTORY_LIST_URL = API_URL+"noninventory/list";