import NonInventoryService from "@/common/noninventory.service";

import {
	LOAD_NONINVENTORY_LIST
} from "@/store/actions.type";
import {
	SET_NONINVENTORY_LIST
} from "@/store/mutations.type";

const state = {
	list: {}
};

const getters = {
	noninventory_list(state) {
		return state.list;
	}
};

const actions = {
	[LOAD_NONINVENTORY_LIST](context, filters) {
		// Make our API CAll
		//console.log("hit module");
		return NonInventoryService.get_noninventory_list(filters)
		.then(data => {
			
			context.commit(SET_NONINVENTORY_LIST, data.data);
			//console.log("things are ok");
			return data;
		});
	}
};

const mutations = {
	[SET_NONINVENTORY_LIST](state, list) {
		state.list = list;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};