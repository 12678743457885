export const getToken = () => {
  return window.localStorage.getItem("cornerstone-user-session-token");
};

export const saveToken = token => {
  window.localStorage.setItem("cornerstone-user-session-token", token);
};

export const destroyToken = () => {
  window.localStorage.removeItem("cornerstone-user-session-token");
};

export default { getToken, saveToken, destroyToken };