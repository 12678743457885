import { ApiService, AuthApiService } from "@/common/api.service";
import {
	LOGIN_URL,
	LOAD_USER_URL,
	SWITCH_COMPANY_URL,
	UPDATE_SETTINGS_URL
} from "@/config/api.endpoints";

const UserService = {
	login(login_info) {
		return ApiService.post(LOGIN_URL, {
			"email": login_info.email,
			"password": login_info.password
		});
	},
	switch_company(company_id) {
		return AuthApiService.post(SWITCH_COMPANY_URL, { "company_id": company_id});
	},
	update_settings(settings) {
		return AuthApiService.post(UPDATE_SETTINGS_URL, settings);
	},
	getUser() {
		return AuthApiService.post(LOAD_USER_URL, {});
	},

	// Local caching of user data. This prevents us from spamming the server,
	// while ensuring we always have user data
	get_user_data(){
		return JSON.parse(window.localStorage.getItem("cornerstone-user-session-data"));
	},
	store_user_data(user){
		return window.localStorage.setItem("cornerstone-user-session-data", JSON.stringify(user));
	},
	wipe_user_data(){
		return window.localStorage.removeItem("cornerstone-user-session-data");
	}
};

export default UserService;