import UsersService from "@/common/users.service";

import {
	ADD_USER,
	LOAD_USER_LIST,
	EDIT_USER,
	SUSPEND_USER,
	ACTIVATE_USER
} from "@/store/actions.type";
import {
	SET_USER_LIST
} from "@/store/mutations.type";

const state = {
	users: {}
};

const getters = {
	users(state) {
		return state.users;
	}
};

const actions = {
	[LOAD_USER_LIST](context, filters) {
		// Make our API CAll
		return UsersService.get_user_list(filters)
		.then(data => {
			context.commit(SET_USER_LIST, data.data);
			return data;
		});
	},
	[ADD_USER](context, user) {
		// Make our API CAll
		return UsersService.add_user(user)
		.then(data => {
			return data;
		});
	},
	[EDIT_USER](context, user) {
		// Make our API CAll
		return UsersService.edit_user(user)
		.then(data => {
			return data;
		});
	},
	[SUSPEND_USER](context, user_id) {
		// Make our API CAll
		return UsersService.suspend_user(user_id)
		.then(data => {
			return data;
		});
	},
	[ACTIVATE_USER](context, user_id) {
		// Make our API CAll
		return UsersService.activate_user(user_id)
		.then(data => {
			return data;
		});
	}
};

const mutations = {
	[SET_USER_LIST](state, users) {
		state.users = users;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};