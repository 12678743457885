import { AuthApiService } from "@/common/api.service";
import {
	GET_NONINVENTORY_LIST_URL
} from "@/config/api.endpoints";

const NonInventoryService = {
	get_noninventory_list(filters) {
		//console.log("service");
		return AuthApiService.post(GET_NONINVENTORY_LIST_URL, filters);
	}
};

export default NonInventoryService;