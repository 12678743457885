import { AuthApiService } from "@/common/api.service";
import {
	GET_USER_LIST_URL,
	ADD_USER_URL,
	EDIT_USER_URL,
	SUSPEND_USER_URL,
	ACTIVATE_USER_URL
} from "@/config/api.endpoints";

const UsersService = {
	get_user_list(filters) {
		return AuthApiService.post(GET_USER_LIST_URL, filters);
	},
	add_user(user) {
		return AuthApiService.post(ADD_USER_URL, user);
	},
	edit_user(user) {
		return AuthApiService.post(EDIT_USER_URL, user);
	},
	suspend_user(id) {
		return AuthApiService.post(SUSPEND_USER_URL, { "user_id": id });
	},
	activate_user(id) {
		return AuthApiService.post(ACTIVATE_USER_URL, { "user_id": id });
	}
};

export default UsersService;