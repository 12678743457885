import axios from "axios";

import store from "@/store";
import TokenService from "@/common/token.service";

import { CLEAR_TOKEN } from "@/store/mutations.type";

export const ApiService = {
	get(resource, params) {
		return new Promise((resolve, reject) => {
			axios.get(resource, { params })
			.then(({data}) => {
				if(!data.success) reject(data.message);
				resolve(data);
			})
			.catch(error => {
				reject("An error occured on the server: "+error);
			});
		});
	},

	post(resource, params) {
		return axios.post(`${resource}`, params)
		.then(({data}) => {
			if(!data.success) throw data.message;
			return data;
		});
	}
};

export const AuthApiService = {
	get(resource, params) {
		if(params === undefined) params = {};
		params.token = TokenService.getToken();
		return ApiService.get(resource, { params })
		.then(data => {
			if(!data.logged_in) {
				store.commit(CLEAR_TOKEN);
				throw "SessionExpired";
			}
			return data;
		});
	},

	post(resource, params) {
		if(params === undefined) params = {};
		params.token = TokenService.getToken();
		return ApiService.post(resource, params)
		.then(data => {
			if(!data.logged_in) {
				store.commit(CLEAR_TOKEN);
				throw "SessionExpired";
			}
			return data;
		});
	},

	form(resource, formData) {
		if(formData === undefined) formData = new FormData();
		formData.append('token', TokenService.getToken());
		return ApiService.post(resource, formData)
		.then(data => {
			if(!data.logged_in) {
				store.commit(CLEAR_TOKEN);
				throw "SessionExpired";
			}
			return data;
		});
	}

}