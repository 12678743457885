import CompanyService from "@/common/company.service";

import {
	LOAD_COMPANY_LIST,
	ADD_COMPANY,
	EDIT_COMPANY
} from "@/store/actions.type";
import {
	SET_COMPANY_LIST
} from "@/store/mutations.type";

const state = {
	list: {}
};

const getters = {
	company_list(state) {
		return state.list;
	}
};

const actions = {
	[LOAD_COMPANY_LIST](context, filters) {
		// Make our API CAll
		return CompanyService.get_company_list(filters)
		.then(data => {
			context.commit(SET_COMPANY_LIST, data.data);
			return data;
		});
	},
	[ADD_COMPANY](context, user) {
		// Make our API CAll
		return CompanyService.add_company(user)
		.then(data => {
			return data;
		});
	},
	[EDIT_COMPANY](context, user) {
		// Make our API CAll
		return CompanyService.edit_company(user)
		.then(data => {
			return data;
		});
	}
};

const mutations = {
	[SET_COMPANY_LIST](state, list) {
		state.list = list;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};