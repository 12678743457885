import InventoryService from "@/common/inventory.service";

import {
	ADD_INVENTORY_ITEM,
	LOAD_INVENTORY_LIST,
	EDIT_INVENTORY_ITEM,
	DELETE_INVENTORY_ITEM
} from "@/store/actions.type";
import {
	SET_INVENTORY_LIST
} from "@/store/mutations.type";

const state = {
	list: {}
};

const getters = {
	inventory_list(state) {
		return state.list;
	}
};

const actions = {
	[LOAD_INVENTORY_LIST](context, filters) {
		// Make our API CAll
		return InventoryService.get_inventory_list(filters)
		.then(data => {
			context.commit(SET_INVENTORY_LIST, data.data);
			return data;
		});
	},
	[ADD_INVENTORY_ITEM](context, user) {
		// Make our API CAll
		return InventoryService.add_inventory_item(user)
		.then(data => {
			return data;
		});
	},
	[EDIT_INVENTORY_ITEM](context, user) {
		// Make our API CAll
		return InventoryService.edit_inventory_item(user)
		.then(data => {
			return data;
		});
	},
	[DELETE_INVENTORY_ITEM](context, user){
		// Make our API CAll
		return InventoryService.delete_inventory_item(user)
		.then(data => {
			return data;
		});
	}
};

const mutations = {
	[SET_INVENTORY_LIST](state, list) {
		state.list = list;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};