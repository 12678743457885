<template>
  <router-view/>
</template>

<style lang="scss">
  @import '@/styles/app.scss';
  @import '@/styles/tooltips.scss';
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');
</style> 

<script>
	import $ from 'jquery';
	import store from "@/store";
	import { LOAD_CATEGORY_LIST } from "@/store/actions.type";
	import { LOAD_DESCRIPTION_LIST } from "@/store/actions.type";
	import { LOAD_SIZE_LIST } from "@/store/actions.type";
	import { LOAD_CONNECTION_LIST } from "@/store/actions.type";
	export default {
		name: 'App',
		components: {},
		created(){
			store.dispatch(LOAD_CATEGORY_LIST, this.new_item).then(() => {    
                    
				}).catch(() => {
					
				}).finally(() => {
					
				});
			store.dispatch(LOAD_DESCRIPTION_LIST, this.new_item).then(() => {    
                    
				}).catch(() => {
					
				}).finally(() => {
					
				});
			store.dispatch(LOAD_SIZE_LIST, this.new_item).then(() => {    
                    
				}).catch(() => {
					
				}).finally(() => {
					
				});
			store.dispatch(LOAD_CONNECTION_LIST, this.new_item).then(() => {    
                    
				}).catch(() => {
					
				}).finally(() => {
					
				});
		},
		mounted(){
			$( window ).scroll(function() {
				if($(window).scrollTop() === 0) {
					$('.header').removeClass('header--scrolled');
					$('.nav').removeClass('nav--scrolled');
				} else {
					$('.header').addClass('header--scrolled');
					$('.nav').addClass('nav--scrolled');
				}
			});
		},
	}
	
</script>