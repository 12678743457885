import ConfigService from "@/common/config.service";

import {
	LOAD_CATEGORY_LIST,
	ADD_CATEGORY_ENTRY,
	EDIT_CATEGORY_ENTRY,
	DELETE_CATEGORY_ENTRY,
	LOAD_DESCRIPTION_LIST,
	LOAD_SIZE_LIST,
	LOAD_CONNECTION_LIST,
} from "@/store/actions.type";
import {
	SET_CATEGORY_LIST,
	SET_DESCRIPTION_LIST,
	SET_SIZE_LIST,
	SET_CONNECTION_LIST
} from "@/store/mutations.type";


const state = {
	categories: [
	],
	descriptions: [
	],
	sizes: [
	],
	connections: [
	]
};

const getters = {
	size_list(state) {
		return state.sizes;
	},
	category_list(state) {
		return state.categories;
	},
	description_list(state) {
		return state.descriptions;
	},
	connector_list(state) {
		return state.connections;
	}
};

const actions = {
	[LOAD_CATEGORY_LIST](context, entry={}) {
		// Make our API CAll
		entry['type'] = 'category';
		return ConfigService.load_category_list(entry)
		.then(data => {
			context.commit(SET_CATEGORY_LIST, data.data);
			return data;
		});
	},
	[ADD_CATEGORY_ENTRY](context, entry) {
		// Make our API CAll
		return ConfigService.add_category_entry(entry)
		.then(data => {
			return data;
		});
	},
	[EDIT_CATEGORY_ENTRY](context, entry) {
		// Make our API CAll
		return ConfigService.edit_category_entry(entry)
		.then(data => {
			return data;
		});
	},
	[DELETE_CATEGORY_ENTRY](context, entry) {
		// Make our API CAll
		return ConfigService.delete_category_entry(entry)
		.then(data => {
			return data;
		});
	},
	[LOAD_DESCRIPTION_LIST](context, entry={}) {
		// Make our API CAll
		entry['type'] = 'description';
		return ConfigService.load_category_list(entry)
		.then(data => {
			context.commit(SET_DESCRIPTION_LIST, data.data);
			return data;
		});
	},
	[LOAD_SIZE_LIST](context, entry={}) {
		// Make our API CAll
		entry['type'] = 'size';
		return ConfigService.load_category_list(entry)
		.then(data => {
			context.commit(SET_SIZE_LIST, data.data);
			return data;
		});
	},
	[LOAD_CONNECTION_LIST](context, entry={}) {
		// Make our API CAll
		entry['type'] = 'connections';
		return ConfigService.load_category_list(entry)
		.then(data => {
			context.commit(SET_CONNECTION_LIST, data.data);
			return data;
		});
	},
	
};

const mutations = {
	[SET_CATEGORY_LIST](state, list) {
		state.categories = list;
	},
	[SET_DESCRIPTION_LIST](state, list) {
		state.descriptions = list;
	},
	[SET_SIZE_LIST](state, list) {
		state.sizes = list;
	},
	[SET_CONNECTION_LIST](state, list) {
		state.connections = list;
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};