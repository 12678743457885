import { AuthApiService } from "@/common/api.service";
import {
	LOAD_TICKET_LIST_URL,
	ADD_TICKET_ENTRY_URL,
	EDIT_TICKET_ENTRY_URL,
	DELETE_TICKET_ENTRY_URL,
	LOAD_TICKET_DETAILS_URL,

	COMPLETE_TICKET_URL,
	CANCEL_TICKET_URL,
	PENDING_TICKET_URL
} from "@/config/api.endpoints";

const TicketService = {
	load_ticket_list(filters={}) {
		return AuthApiService.post(LOAD_TICKET_LIST_URL, filters);
	},
	get_ticket_details(ticket_id) {
		return AuthApiService.post(LOAD_TICKET_DETAILS_URL, {'ticket_id': ticket_id});
	},
	add_ticket_entry(entry) {
		return AuthApiService.post(ADD_TICKET_ENTRY_URL, entry);
	},
	edit_ticket_entry(entry) {
		return AuthApiService.post(EDIT_TICKET_ENTRY_URL, entry);
	},
	delete_ticket_entry(entry) {
		return AuthApiService.post(DELETE_TICKET_ENTRY_URL, entry);
	},

	complete_ticket(entry) {
		return AuthApiService.post(COMPLETE_TICKET_URL, entry);
	},
	cancel_ticket(entry) {
		return AuthApiService.post(CANCEL_TICKET_URL, entry);
	},
	pending_ticket(entry) {
		return AuthApiService.post(PENDING_TICKET_URL, entry);
	},
	
};

export default TicketService;