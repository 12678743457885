import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

import {ON_LOAD, OFF_LOAD, VALIDATE_SESSION, LOAD_USER } from "@/store/actions.type";

const routes = [
  {
    path: '/', 
    redirect: '/inventory'
  },
  // Admin wrapped pages
  {
    path: "/",
    component: () => import("@/components/Layout/Layout"),
    children: [
      // {
      //   path: '/dashboard', 
      //   name: "dashboard",
      //   component: () => import("@/views/Dashboard/Dashboard"),
      //   props: true 
      // },
      {
        path: '/inventory', 
        name: "inventory",
        component: () => import("@/views/Inventory/Inventory"),
        props: true 
      },
      {
        path: '/non-inventory', 
        name: "non_inventory",
        component: () => import("@/views/Noninventory/List"),
        props: true 
      },
      {
        path: '/config', 
        name: "config",
        component: () => import("@/views/Config/Config"),
        props: true 
      },
      {
        path: '/ticket', 
        name: "ticket_list",
        component: () => import("@/views/Ticket/List"),
        props: true 
      },
      {
        path: '/ticket/add', 
        name: "ticket_add",
        component: () => import("@/views/Ticket/Add"),
        props: true 
      },
      {
        path: '/ticket/edit/:id', 
        name: "ticket_edit",
        component: () => import("@/views/Ticket/Edit"),
        props: true 
      },
      {
        path: '/ticket/view/:id', 
        name: "ticket_view",
        component: () => import("@/views/Ticket/View"),
        props: true 
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/Settings/Settings"),
        props: true
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/Users/List"),
        props: true
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/Admin/Companies"),
        props: true
      },
      
    ]
  },
  // Login page
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/Login"),
    props: true 
  },
  // Recovery page
  {
    path: "/recover/:code",
    name: "recover",
    component: () => import("@/views/Login/Recover"),
    props: true
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/inventory'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function(to, from, next) {
	console.log(to);
	// If login page or recovery -> skip auth
	if(["login", "recover"].includes(to.name)) {
		next();
	} else {
		store.dispatch(ON_LOAD);
		// Otherwise, make sure we have active session
		Promise.all([
      store.dispatch(VALIDATE_SESSION),
      store.dispatch(LOAD_USER)
    ]).then(() => {
      store.dispatch(OFF_LOAD);
      next();
    }).catch((error) => {
      // Invalid Session. Push to login.
      router.replace({ name: 'login', query: { date: Date.now() }, params: { error: error }});
    });
	}
});

export default router
