import {
    ON_LOAD, 
    OFF_LOAD
} from "@/store/actions.type";
import {
    SET_LOADING
} from "@/store/mutations.type";

const state = {
    loading: true
};

const getters = {
    loading(state) {
        return state.loading;
    }
};

const actions = {
    [ON_LOAD](context) {
        // Make our API CAll
        context.commit(SET_LOADING, true);
    },
    [OFF_LOAD](context) {
        context.commit(SET_LOADING, false);
    }
};

const mutations = {
    [SET_LOADING](state, status) {
        state.loading = status;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};