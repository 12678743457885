import { AuthApiService } from "@/common/api.service";
import {
	GET_COMPANY_LIST_URL,
	ADD_COMPANY_URL,
	EDIT_COMPANY_URL
} from "@/config/api.endpoints";

const CompanyService = {
	get_company_list(filters) {
		return AuthApiService.post(GET_COMPANY_LIST_URL, filters);
	},
	add_company(user) {
		return AuthApiService.post(ADD_COMPANY_URL, user);
	},
	edit_company(user) {
		return AuthApiService.post(EDIT_COMPANY_URL, user);
	}
};

export default CompanyService;